var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Q5,dsa,R5,esa,fsa,gsa,hsa,W5,X5,Z5,$5,c6,d6,isa,U5,jsa;$CLJS.L5=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.hZ);b=$CLJS.U0(a,b);return 0<b?b-1:null};$CLJS.M5=function(a,b,c,d){d=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,4,[$CLJS.s1,$CLJS.c3(),$CLJS.q1,!0,$CLJS.v1,!0,$CLJS.n1,!0],null),d]));return $CLJS.D1.D(a,b,c,d)};$CLJS.N5=function(a,b,c){return $CLJS.M5(a,b,c,null)};
$CLJS.O5=function(a){var b=$CLJS.CH(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "field":return $CLJS.lQ.g($CLJS.EH(a));case "metadata/column":return $CLJS.W3.g(a);case "mbql/join":return $CLJS.vP.g(a);default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.P5=function(a){return $CLJS.i5.g(a)};Q5=function(a,b){return $CLJS.F.h($CLJS.z4.g(a),$CLJS.li(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WK,$CLJS.CI,$CLJS.TK],null)))};
dsa=function(a,b){b=$CLJS.Y0(a,b);return $CLJS.m(b)?$CLJS.q5(a,b):null};R5=function(a){var b=new $CLJS.k(null,3,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.CG,$CLJS.CG.g(a),$CLJS.lk,$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(a)],null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zG,b,$CLJS.V.g(a)],null)};esa=function(a){return $CLJS.R3(a,new $CLJS.k(null,1,[$CLJS.WK,$CLJS.Oi],null))};
fsa=function(a,b,c){var d=$CLJS.fn.l;b=$CLJS.Gb($CLJS.L5(a,b))?function(){var f=$CLJS.xZ.g($CLJS.C($CLJS.hZ.g(a)));return $CLJS.m(f)?(f=dsa(a,f),$CLJS.m(f)?$CLJS.vW(function(g){return $CLJS.F.h($CLJS.Gk.g(g),c)},f):null):null}():null;try{var e=$CLJS.s0(a,c)}catch(f){e=null}return d.call($CLJS.fn,$CLJS.G([b,e]))};
gsa=function(a,b){var c=$CLJS.vW(function(d){return $CLJS.F.h($CLJS.A0.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.vW(function(d){return $CLJS.F.h($CLJS.V.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.XF($CLJS.XD);$CLJS.m($CLJS.dG("metabase.lib.field",c))&&(b=$CLJS.UH("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([a])),$CLJS.aj.l($CLJS.G([$CLJS.Pm.h($CLJS.A0,b)]))])),b instanceof Error?$CLJS.eG("metabase.lib.field",c,$CLJS.UB(),b):$CLJS.eG("metabase.lib.field",
c,$CLJS.UB.l($CLJS.G([b])),null));return null};
hsa=function(a,b,c){if($CLJS.m(S5))return null;var d=S5;S5=!0;try{var e=$CLJS.L5(a,b),f=$CLJS.m(e)?$CLJS.V0(a,e):$CLJS.V0(a,b),g=function(){var n=$CLJS.T5.g(f);if($CLJS.m(n))return n;n=$CLJS.Ju(f,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oZ,$CLJS.d0],null));if($CLJS.m(n))return n;n=$CLJS.m($CLJS.xZ.g(f))?$CLJS.N5(a,b,f):null;if($CLJS.m(n))return n;n=$CLJS.XF($CLJS.XD);if($CLJS.m($CLJS.dG("metabase.lib.field",n))){var q=$CLJS.UH("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.aj.l($CLJS.G([c]))]));
return q instanceof Error?$CLJS.eG("metabase.lib.field",n,$CLJS.UB(),q):$CLJS.eG("metabase.lib.field",n,$CLJS.UB.l($CLJS.G([q])),null)}return null}(),l=function(){var n=$CLJS.A(g);return n?gsa(c,g):n}();return $CLJS.m(l)?$CLJS.m(e)?$CLJS.U.j($CLJS.U.j($CLJS.X3($CLJS.Dm.l(l,$CLJS.Gk,$CLJS.G([$CLJS.k0,U5,$CLJS.V5])),null),$CLJS.V,function(){var n=$CLJS.A0.g(l);return $CLJS.m(n)?n:$CLJS.V.g(l)}()),$CLJS.g0,$CLJS.u0):l:null}finally{S5=d}};
W5=function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.lQ);var f=$CLJS.J(c,2,null);c=$CLJS.fn.l($CLJS.G([function(){var g=$CLJS.CG.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.CG,g],null):null}(),function(){var g=$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.lk,g],null):null}(),function(){var g=$CLJS.OR.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[U5,g],null):null}(),function(){var g=$CLJS.PQ.g(e);return $CLJS.m(g)?new $CLJS.k(null,
1,[$CLJS.V5,g],null):null}(),$CLJS.Ae(f)?function(){var g=fsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.V,f],null)}():$CLJS.m(d)?new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.V,f],null):function(){var g=hsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.V,f],null)}()]));return $CLJS.m(d)?$CLJS.X3(c,d):c};X5=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.V5);return $CLJS.m($CLJS.m(b)?$CLJS.Ie($CLJS.VV,b):b)?$CLJS.il:$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(a)};
$CLJS.Y5=function(a,b){return"string"===typeof b?$CLJS.Y0(a,$CLJS.f0(b)):$CLJS.Ae(b)?$CLJS.X0(a,b):null};Z5=function(a,b,c){return $CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Wj.g(d),c)},a))?$CLJS.Pm.h(function(d){var e=$CLJS.Dm.h(d,b);return $CLJS.F.h($CLJS.Wj.g(d),c)?$CLJS.U.j(e,b,!0):e},a):a};
$5=function(a){var b=function(){var d=$CLJS.g0.g(a),e=new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.C0,null,$CLJS.E0,null,$CLJS.u0,null],null),null);return e.g?e.g(d):e.call(null,d)}(),c=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.CG,$CLJS.CG.g(a),$CLJS.lk,X5(a)],null),function(){var d=$CLJS.O5(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.lQ,d],null):null}(),function(){var d=$CLJS.V5.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.PQ,d],null):null}(),function(){var d=
U5.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.OR,d],null):null}(),function(){var d=$CLJS.iR.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.VO,d],null):null}()]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,$CLJS.m(b)?function(){var d=$CLJS.A0.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}():function(){var d=$CLJS.Gk.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}()],null)};
$CLJS.a6=function a6(a){switch(arguments.length){case 2:return a6.h(arguments[0],arguments[1]);case 3:return a6.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.a6.h=function(a,b){return $CLJS.a6.j(a,-1,b)};$CLJS.a6.j=function(a,b,c){return $CLJS.I1.j(a,b,c)};$CLJS.a6.v=3;
$CLJS.b6=function b6(a){switch(arguments.length){case 2:return b6.h(arguments[0],arguments[1]);case 3:return b6.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.b6.h=function(a,b){return $CLJS.b6.j(a,-1,b)};$CLJS.b6.j=function(a,b,c){return $CLJS.j5.j(a,b,c)};$CLJS.b6.v=3;$CLJS.V5=new $CLJS.N("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);
c6=new $CLJS.N(null,"parent-id","parent-id",-1400729131);$CLJS.T5=new $CLJS.N("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);d6=new $CLJS.N("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);isa=new $CLJS.N(null,"earliest","earliest",-1928154382);U5=new $CLJS.N("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);jsa=new $CLJS.N(null,"latest","latest",24323665);$CLJS.S3.o(null,$CLJS.DQ,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Oi.g(b),$CLJS.R3(c,new $CLJS.k(null,2,[$CLJS.PQ,$CLJS.Oi,$CLJS.OR,esa],null)),a],null)});var S5=!1,e6=function e6(a,b){var d=$CLJS.s0(a,c6.g(b));a=$CLJS.m(c6.g(d))?e6.h?e6.h(a,d):e6.call(null,a,d):d;a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.V);return $CLJS.Qm.j(b,$CLJS.V,function(f){return[$CLJS.p.g(e),".",$CLJS.p.g(f)].join("")})};
$CLJS.A1.o(null,$CLJS.hW,function(a,b,c){return X5(c)});$CLJS.A1.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.PQ);$CLJS.J(c,2,null);c=W5(a,b,c);c=$CLJS.m(d)?$CLJS.U.j(c,$CLJS.V5,d):c;return $CLJS.B1.j(a,b,c)});$CLJS.Z0.o(null,$CLJS.hW,function(a,b,c){a=$CLJS.Qf(c);b=$CLJS.M.h(a,$CLJS.V);return $CLJS.U.j(a,$CLJS.V,b)});
$CLJS.Z0.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d),f=$CLJS.M.h(e,$CLJS.HG),g=$CLJS.M.h(e,$CLJS.CG),l=$CLJS.M.h(e,$CLJS.OR),n=$CLJS.M.h(e,$CLJS.lk);d=$CLJS.M.h(e,$CLJS.lQ);var q=$CLJS.M.h(e,$CLJS.VO),u=$CLJS.M.h(e,$CLJS.PQ),v=W5(a,b,c),x=$CLJS.fn.l;f=new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.E1,f],null);e=$CLJS.TI.g(e);b=$CLJS.m(e)?e:$CLJS.a1.j(a,b,c);b=x.call($CLJS.fn,$CLJS.G([f,v,new $CLJS.k(null,1,[$CLJS.TI,b],null),$CLJS.m(n)?new $CLJS.k(null,1,[$CLJS.lk,
n],null):null,$CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.CG,g],null):null,$CLJS.m(u)?new $CLJS.k(null,1,[$CLJS.V5,u],null):null,$CLJS.m(l)?new $CLJS.k(null,1,[U5,l],null):null,$CLJS.m(q)?new $CLJS.k(null,1,[$CLJS.iR,q],null):null]));d=$CLJS.m(d)?$CLJS.X3(b,d):b;return $CLJS.m(c6.g(d))?e6(a,d):d});
$CLJS.w1.o(null,$CLJS.hW,function(a,b,c,d){c=$CLJS.Qf(c);var e=$CLJS.M.h(c,$CLJS.TI),f=$CLJS.M.h(c,$CLJS.V),g=$CLJS.M.h(c,$CLJS.Wj),l=$CLJS.M.h(c,U5),n=$CLJS.M.h(c,$CLJS.H0),q=$CLJS.M.h(c,$CLJS.iR),u=$CLJS.M.h(c,$CLJS.k0);e=$CLJS.m(e)?e:"string"===typeof f?$CLJS.m5.h($CLJS.KG,f):$CLJS.p.g(f);$CLJS.F.h(d,$CLJS.b1)&&-1==e.indexOf(" → ")?($CLJS.m(q)?(q=$CLJS.s0(a,q),$CLJS.m(q)?a=(0,$CLJS.Ca)($CLJS.Nu($CLJS.TI.g($CLJS.d1.j(a,b,q)),$CLJS.n0,"")):(u=$CLJS.Y5(a,u),a=$CLJS.a1.D(a,b,u,d))):a=null,n=$CLJS.m(a)?
a:$CLJS.m(n)?n:$CLJS.O5(c)):n=null;n=$CLJS.m(n)?[$CLJS.p.g(n)," → ",$CLJS.p.g(e)].join(""):e;return $CLJS.m(g)?(c=$CLJS.lG($CLJS.Nu($CLJS.ui(g),"-"," ")),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):$CLJS.m(l)?(c=$CLJS.f5(l,c),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):n});
$CLJS.w1.o(null,$CLJS.DQ,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.Qf(e);e=$CLJS.M.h(f,$CLJS.OR);var g=$CLJS.M.h(f,$CLJS.lQ),l=$CLJS.M.h(f,$CLJS.PQ);f=$CLJS.M.h(f,$CLJS.VO);$CLJS.J(c,2,null);c=W5(a,b,c);c=$CLJS.m(g)?$CLJS.U.j(c,$CLJS.H0,g):c;l=$CLJS.m(l)?$CLJS.U.j(c,$CLJS.Wj,l):c;e=$CLJS.m(e)?$CLJS.U.j(l,U5,e):l;e=$CLJS.m(f)?$CLJS.U.j(e,$CLJS.iR,f):e;return $CLJS.m(e)?$CLJS.a1.D(a,b,e,d):$CLJS.VH("[Unknown Field]")});
$CLJS.x1.o(null,$CLJS.hW,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.V)});$CLJS.x1.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=W5(a,b,c);return $CLJS.m(c)?$CLJS.y1.j(a,b,c):"unknown_field"});
$CLJS.C1.o(null,$CLJS.hW,function(a,b,c){return $CLJS.fn.l($CLJS.G([function(){var d=$CLJS.tj($CLJS.C1,$CLJS.Pj);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.h($CLJS.g0.g(c),$CLJS.C0)?function(){var d=$CLJS.p5.g(c);return $CLJS.m(d)?(d=$CLJS.Y0(a,d),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.l0,new $CLJS.k(null,2,[$CLJS.V,$CLJS.V.g(d),$CLJS.TI,$CLJS.V.g(d)],null)],null):null):null}():null]))});$CLJS.H1.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);return $CLJS.PQ.g(b)});
$CLJS.H1.o(null,$CLJS.hW,function(a){return $CLJS.V5.g(a)});
$CLJS.G1.o(null,$CLJS.DQ,function(a,b){$CLJS.J(a,0,null);var c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(b)){var d=$CLJS.Ie($CLJS.VV,b),e=$CLJS.Nm.j(d6,$CLJS.lk,$CLJS.CG)(c);c=$CLJS.U.l(c,$CLJS.PQ,b,$CLJS.G([$CLJS.lk,d?$CLJS.il:e,d6,e]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,a],null)}b=d6.g(c);c=$CLJS.m(b)?$CLJS.Dm.h($CLJS.U.j(c,$CLJS.lk,b),d6):c;c=$CLJS.Dm.h(c,$CLJS.PQ);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,a],null)});
$CLJS.G1.o(null,$CLJS.hW,function(a,b){return $CLJS.m(b)?$CLJS.U.j(a,$CLJS.V5,b):$CLJS.Dm.h(a,$CLJS.V5)});$CLJS.I1.o(null,$CLJS.DQ,function(a,b,c){return $CLJS.a6.j(a,b,W5(a,b,c))});
$CLJS.I1.o(null,$CLJS.hW,function(a,b,c){if($CLJS.ki.h($CLJS.g0.g(c),$CLJS.x0)){a=$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(c);b=null==c?null:$CLJS.dP.g(c);if(null==b)var d=null;else try{var e=$CLJS.SJ.g($CLJS.Wk.g(b));if($CLJS.m(e)){var f=$CLJS.Qf(e),g=$CLJS.M.h(f,isa),l=$CLJS.M.h(f,jsa),n=$CLJS.csa.l($CLJS.G([$CLJS.K5.g(g),$CLJS.K5.g(l)]));d=isNaN(n)?null:$CLJS.m($CLJS.mt.h?$CLJS.mt.h(1,n):$CLJS.mt.call(null,1,n))?$CLJS.uz:$CLJS.m($CLJS.mt.h?$CLJS.mt.h(31,n):$CLJS.mt.call(null,31,n))?$CLJS.wz:$CLJS.m($CLJS.mt.h?
$CLJS.mt.h(365,n):$CLJS.mt.call(null,365,n))?$CLJS.Yk:$CLJS.jl}else d=null}catch(q){if(q instanceof Error)d=null;else throw q;}e=$CLJS.OH(a,$CLJS.SJ)?$CLJS.ipa:$CLJS.OH(a,$CLJS.OI)?$CLJS.hpa:$CLJS.OH(a,$CLJS.XK)?$CLJS.gpa:$CLJS.Lg;d=$CLJS.m(d)?Z5(e,$CLJS.Pj,d):e;return $CLJS.m($CLJS.V5.g(c))?Z5(d,$CLJS.i1,$CLJS.V5.g(c)):d}return $CLJS.Lg});
$CLJS.i5.o(null,$CLJS.DQ,function(a){var b=null==a?null:$CLJS.EH(a);b=null==b?null:$CLJS.OR.g(b);return null==b?null:$CLJS.U.l(b,$CLJS.rl,$CLJS.g5,$CLJS.G([$CLJS.v0,function(c,d){return W5(c,d,a)}]))});$CLJS.i5.o(null,$CLJS.hW,function(a){var b=null==a?null:U5.g(a);return null==b?null:$CLJS.U.l(b,$CLJS.rl,$CLJS.g5,$CLJS.G([$CLJS.v0,$CLJS.ag(a)]))});$CLJS.h5.o(null,$CLJS.DQ,function(a,b){return $CLJS.dW(a,$CLJS.cW,$CLJS.G([$CLJS.OR,b]))});
$CLJS.h5.o(null,$CLJS.hW,function(a,b){return $CLJS.cW(a,U5,b)});$CLJS.j5.o(null,$CLJS.DQ,function(a,b,c){return $CLJS.b6.j(a,b,W5(a,b,c))});
$CLJS.j5.o(null,$CLJS.hW,function(a,b,c){b=$CLJS.Qf(c);c=$CLJS.M.h(b,$CLJS.lk);var d=$CLJS.M.h(b,$CLJS.dP),e=$CLJS.M.h(b,$CLJS.Kl);if($CLJS.ki.h($CLJS.g0.g(b),$CLJS.x0)){var f=function(){var n=null==a?null:$CLJS.T3($CLJS.r0(a));n=null==n?null:$CLJS.wF.g(n);return null==n?null:$CLJS.Ie(n,$CLJS.OR)}(),g=$CLJS.Ju(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk,$CLJS.El],null)),l=$CLJS.P5(b);return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),
y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var I=0;;)if(I<y){var Q=$CLJS.be(x,I),Y=B,aa=Q;Q=Q5(Q,l)?$CLJS.U.j(aa,$CLJS.i1,!0):aa;Y.add(Q);I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v);return $CLJS.nf(function(){var I=H;return Q5(H,l)?$CLJS.U.j(I,$CLJS.i1,!0):I}(),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Gb(function(){if($CLJS.m(f)){var q=$CLJS.qk.g(g);return $CLJS.m(q)?$CLJS.Yl.g(g):q}return f}())?null:$CLJS.OH(e,$CLJS.ll)?$CLJS.r($CLJS.xra):
$CLJS.OH(c,$CLJS.El)&&!$CLJS.OH(e,$CLJS.Qj)?$CLJS.r($CLJS.wra):null)}return $CLJS.Lg});$CLJS.bW.o(null,$CLJS.DQ,function(a){return a});
$CLJS.bW.o(null,$CLJS.hW,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.g0);switch(b instanceof $CLJS.N?b.S:null){case "source/aggregations":return b=new $CLJS.k(null,2,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.lk,$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(a)],null),a=$CLJS.E1.g(a),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MP,b,a],null);case "source/expressions":return R5(a);case "source/breakouts":return $CLJS.Ie(a,$CLJS.LL)?R5(a):$5(a);default:return $5(a)}});